import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import carService from 'src/services/car';
import makeForm from 'src/utils/makeForm';
import palette from 'src/theme/palette';
import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import _ from 'underscore';
import TextControl from 'src/components/form/TextControl';
import ImageControl from 'src/components/form/ImageControl';
import SelectControl from 'src/components/form/SelectControl';
import CheckboxControl from 'src/components/form/CheckboxControl';
import brandService from 'src/services/brand';

const defaultValues = {
  id: '',
  name: '',
  modelId: '',
  brandName: '',
  image: '',
  fuelTypeId: '',
  carTypeId: '',
  color: '',
  isActive: true,
  launchPrice: '',
  isFixedPrice: false,
  isFixThirdPrice: false,
  isFixManufacturePrice: false,
  isFixCashPrice: false,
  cashPrice: '',
  manufacturePrice: '',
  thirdPrice: '',
  // newly added
  brandId: ''
};

const schema = yup
  .object()
  .shape({
    name: yup.string().trim().required('Please enter name of trim.'),
    modelId: yup.string().trim().required('Please select name of model.'),
    fuelTypeId: yup.number().required(),
    carTypeId: yup.number().required(),
    isActive: yup.boolean(),
    launchPrice: yup.string().trim().required(),
    color: yup.string().required(),
    isFixedPrice: yup.boolean(),
    isFixCashPrice: yup.boolean(),
    isFixManufacturePrice: yup.boolean(),
    isFixThirdPrice: yup.boolean(),
    cashPrice: yup.string().trim().nullable(),
    manufacturePrice: yup.string().trim().nullable(),
    thirdPrice: yup.string().trim().nullable()
  })
  .required();

export default function EditCarTrim({ carData }) {
  const [loading, setLoading] = useState();
  const { handleSubmit, control, reset, watch, setValue } = useForm({ defaultValues });
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('car-trim');
  const editing = (id ?? '') !== 'new' && id !== 'new';
  const [options, setOptions] = useState({
    brand: [],
    model: [],
    fuelType: [],
    carType: []
  });
  const getOptions = async () => {
    const brand = (await carService.listOptionsAsync('manufacturer'))?.data;
    const model = (await carService.listOptionsAsync('car-model'))?.data;
    const fuelType = (await carService.listOptionsAsync('fuel-type'))?.data;
    const carType = (await carService.listOptionsAsync('car-type'))?.data;
    setOptions({ brand, model, fuelType, carType });
  };
  useEffect(() => {
    if (carData) {
      const _carData = _.pick(carData, Object.keys(defaultValues));
      _carData.image = JSON.parse(_carData.image)?.[0];
      reset(_carData);
    } else {
      reset(defaultValues);
    }
  }, [carData]);

  useEffect(() => {
    getOptions();
  }, []);

  const onSubmit = async (data) => {
    const _data = {
      ..._.omit(data, [
        'isFixCachPrice',
        'isFixManufacturePrice',
        'isFixThirdPrice',
        'cashPrice',
        'manufacturePrice',
        'thirdPrice',
        'image'
      ])
    };
    if (data.isFixCashPrice) _data.cashPrice = data.cashPrice || null;
    if (data.isFixManufacturePrice) _data.manufacturePrice = data.manufacturePrice || null;
    if (data.isFixThirdPrice) _data.thirdPrice = data.thirdPrice || null;
    if (typeof data.image === 'object') _data.image = data.image;

    const fData = makeForm(_data);
    if (editing) {
      console.log('submit for editing', _data);
      const reqSubmit = await carService.updateAsync(data.id, 'car-trim', fData);
      setLoading(false);
      return;
    }
    console.log('submit for creation', _data);
    const reqSubmit = await carService.createAsync('car-trim', fData);
    setLoading(false);
  };

  // const isFixedPrice = watch('isFixedPrice');
  const isFixCashPrice = watch('isFixCashPrice');
  const isFixManufacturePrice = watch('isFixManufacturePrice');
  const isFixThirdPrice = watch('isFixThirdPrice');
  const brandId = watch('brandId');
  useEffect(() => {
    (async () => {
      if (brandId) {
        setValue('modelId', '');
        const model = await brandService.getModels(brandId)?.data;
        if (model) setOptions({ ...options, model });
      }
    })();
  }, [brandId]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: '100%',
        maxWidth: 500,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="h4"
        sx={{
          padding: '10px 20px',
          borderBottom: `1px solid ${palette.grey[400]}`
        }}
      >
        {editing ? 'Edit Car Trim' : 'New Car Trim'}
      </Typography>
      <Box px="24px" sx={{ '& > *': { mt: '24px' } }}>
        <SelectControl
          name="brandId"
          control={control}
          size="small"
          label="Brand"
          fullWidth
          options={options.brand}
          getValue={(v) => v.id}
          getLabel={(v) => v.name}
        />
        <SelectControl
          name="modelId"
          control={control}
          size="small"
          label="Model Name"
          fullWidth
          options={options.model}
          getValue={(i) => i.id}
          getLabel={(i) => i.name}
        />
        <TextControl name="trimName" control={control} size="small" label="Trim Name" fullWidth />
        <ImageControl control={control} name="image" />
        <SelectControl
          name="fuelTypeId"
          control={control}
          size="small"
          label="Fuel Type"
          fullWidth
          options={options.fuelType}
          getValue={(i) => i.id}
          getLabel={(i) => i.name}
        />
        <SelectControl
          name="carTypeId"
          control={control}
          size="small"
          label="Car Type"
          fullWidth
          options={options.fuelType}
          getValue={(i) => i.id}
          getLabel={(i) => i.name}
        />
        <TextControl name="color" control={control} size="small" label="Colors" fullWidth />
        <CheckboxControl name="isActive" size="small" label="active" control={control} />
        <TextControl
          name="launchPrice"
          control={control}
          size="small"
          label="Launch Price"
          fullWidth
        />
        <CheckboxControl
          name="isFixedPrice"
          control={control}
          label="Fix Recommended Price"
          size="small"
          fullWidth
          indeterminate={
            Array.from(new Set([isFixCashPrice, isFixManufacturePrice, isFixThirdPrice])).length > 1
          }
          onChange={(ev) => {
            const checked = ev.target.checked;
            setValue('isFixCashPrice', checked);
            setValue('isFixManufacturePrice', checked);
            setValue('isFixThirdPrice', checked);
            setValue('isFixedPrice', checked);
          }}
        />
        <Stack direction="row">
          <CheckboxControl
            name="isFixCashPrice"
            size="small"
            control={control}
            onChange={(ev) => {
              const checked = ev.target.checked;
              setValue('isFixCashPrice', checked);
              const allEqual =
                Array.from(new Set([isFixCashPrice, isFixManufacturePrice, isFixThirdPrice]))
                  .length > 1;
              if (allEqual) setValue('isFixedPrice', checked);
            }}
          />
          <TextControl
            disabled={!isFixCashPrice}
            name="cashPrice"
            type="number"
            control={control}
            size="small"
            label="Cash Price"
            fullWidth
          />
        </Stack>
        <Stack direction="row">
          <CheckboxControl
            name="isFixManufacturePrice"
            size="small"
            control={control}
            onChange={(ev) => {
              const checked = ev.target.checked;
              setValue('isFixManufacturePrice', checked);
              const allEqual =
                Array.from(new Set([isFixCashPrice, isFixManufacturePrice, isFixThirdPrice]))
                  .length > 1;
              if (allEqual) setValue('isFIxedPrice', checked);
            }}
          />
          <TextControl
            disabled={!isFixManufacturePrice}
            name="manufacturePrice"
            type="number"
            control={control}
            size="small"
            label="Manufacture Price"
            fullWidth
          />
        </Stack>
        <Stack direction="row">
          <CheckboxControl
            name="isFixThirdPrice"
            size="small"
            control={control}
            onChange={(ev) => {
              const checked = ev.target.checked;
              setValue('isFixThirdPrice', checked);
              const allEqual =
                Array.from(new Set([isFixCashPrice, isFixManufacturePrice, isFixThirdPrice]))
                  .length > 1;
              if (allEqual) setValue('isFixedPrice', checked);
            }}
          />
          <TextControl
            disabled={!isFixThirdPrice}
            name="thirdPrice"
            type="number"
            control={control}
            size="small"
            label="Third Party Price"
            fullWidth
          />
        </Stack>
      </Box>
    </Box>
  );
}
