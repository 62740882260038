import { Box, Modal } from '@mui/material';
import { useState, useEffect } from 'react';
import privateStore from 'src/stores/PrivateStore';
import { getFullImageUrl } from 'src/utils/imageUrl';

export default function LoadImage({ Component, src, file, clickable, ...props }) {
  const [url, setUrl] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  const reValidUrl = /^(http|https):\/\/.+/i;

  useEffect(() => {
    if (src && !reValidUrl.test(src)) {
      const token = privateStore.getToken();

      fetch(getFullImageUrl(src), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Headers': '*'
        }
      })
        .then((r) => r.blob())
        .then((d) => setUrl(window.URL.createObjectURL(d)))
        .catch((e) => {
          setUrl(null);
        });
    }
  }, [src]);

  useEffect(() => {
    if (file) {
      setUrl(window.URL.createObjectURL(file));
    }
  }, [file]);

  const _src = src && reValidUrl.test(src) ? src : url;
  console.log(src, url, _src);
  if (!_src) return null;
  if (clickable)
    return (
      <>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box component="img" src={_src} />
        </Modal>
        <Component src={_src} onClick={() => setOpenModal(true)} {...props} />
      </>
    );
  return <Component src={_src} {...props} />;
}
