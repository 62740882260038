/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from '@mui/lab';
import { Button, ButtonGroup, Checkbox, Container, Stack, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import LoadImage from 'src/components/LoadImage';
import Page from 'src/components/Page';
import RightDrawer from 'src/components/RightDrawer';
import Table from 'src/components/Table';
import { carMode, checkBoxFunction, fieldType } from 'src/constants';
import carService from 'src/services/car';
import { downloadFile } from 'src/utils';
import CarForm from './components/CarForm';
import EditCarModel from './components/EditCarModel';
import EditCarTrim from './components/EditCarTrim';

const configs = {
  'car-model': [
    {
      key: 'name',
      name: 'Name'
    },
    { key: 'brandName', name: 'Brand' },
    { key: 'numOfTrim', name: 'Num Of Trims' },
    {
      key: 'color',
      name: 'Colors'
    },
    { key: 'fuelType', name: 'Fuel Types' },
    { key: 'carType', name: 'Vehicle Types' }
  ],
  'car-trim': [
    {
      key: 'name',
      name: 'Trim Name'
    },
    { key: 'brandName', name: 'Brand' },
    { key: 'modelName', name: 'Model' },
    {
      key: 'color',
      name: 'Colors'
    },
    {
      key: 'launchPrice',
      name: 'Price'
    },
    { key: 'fuelTypeName', name: 'Fuel Types' },
    { key: 'carTypeName', name: 'Car Types' },
    {
      key: 'isActive',
      name: 'Active',
      render: (row) => <Checkbox checked={row.isActive} />
    }
  ]
};

const formSchema = {
  'car-model': [
    {
      key: 'modelName',
      name: 'name',
      type: fieldType.TEXT,
      errorField: 'name',
      fieldProps: {
        type: 'text',
        required: true,
        size: 'small',
        label: 'Model Name',
        fullWidth: true
      }
    },
    {
      key: 'brand',
      name: 'brandId',
      type: fieldType.SELECT,
      errorField: 'brandId',
      fieldProps: {
        required: true,
        size: 'small',
        label: 'Brand Name',
        fullWidth: true
      }
    },
    {
      key: 'color',
      name: 'color',
      label: 'Colors',
      type: fieldType.LABEL
    },
    {
      key: 'fuelType',
      name: 'fuelType',
      label: 'Fuel Type',
      type: fieldType.LABEL
    },
    {
      key: 'carType',
      name: 'carType',
      label: 'Car Type',
      type: fieldType.LABEL
    }
  ],
  'car-trim': [
    {
      key: 'brandName',
      name: 'brandName',
      type: fieldType.READONLY_TEXT,
      fieldProps: {
        type: 'text',
        size: 'small',
        label: 'Brand Name',
        fullWidth: true
      }
    },
    {
      key: 'trimName',
      name: 'name',
      type: fieldType.TEXT,
      errorField: 'name',
      fieldProps: {
        type: 'text',
        required: true,
        size: 'small',
        label: 'Trim Name',
        fullWidth: true
      }
    },
    {
      key: 'image',
      name: 'image',
      type: fieldType.MULTIPLE_IMAGE,
      render: (src) => {
        if (!src) {
          return null;
        }

        if (typeof src === 'object') {
          return (
            <LoadImage Component={Image} file={src} alt={src.name} style={{ margin: '10px' }} />
          );
        }

        const images = JSON.parse(src || '[]');
        console.log(images);
        return (
          <div>
            {images.map((image, key) => (
              <LoadImage
                key={key}
                Component={Image}
                alt="image"
                src={image}
                imgProps={{ style: { objectFit: 'contain' } }}
                variant="square"
              />
            ))}
          </div>
        );
      }
    },
    {
      key: 'model',
      name: 'modelId',
      type: fieldType.SELECT,
      errorField: 'modelId',
      fieldProps: {
        required: true,
        size: 'small',
        label: 'Model Name',
        fullWidth: true
      }
    },
    {
      key: 'fuelType',
      name: 'fuelTypeId',
      type: fieldType.SELECT,
      errorField: 'fuelTypeId',
      fieldProps: {
        required: true,
        size: 'small',
        label: 'Fuel Type',
        fullWidth: true
      }
    },
    {
      key: 'carType',
      name: 'carTypeId',
      type: fieldType.SELECT,
      errorField: 'carTypeId',
      fieldProps: {
        required: true,
        size: 'small',
        label: 'Car Type',
        fullWidth: true
      }
    },
    {
      key: 'color',
      name: 'color',
      type: fieldType.TEXT,
      fieldProps: {
        size: 'small',
        label: 'Colors',
        fullWidth: true
      }
    },
    {
      key: 'isActive',
      name: 'isActive',
      type: fieldType.CHECK_BOX,
      fieldProps: {
        required: true,
        size: 'small',
        label: 'Active'
      }
    },
    {
      key: 'launchPrice',
      name: 'launchPrice',
      type: fieldType.NUMBER,
      errorField: 'launchPrice',
      fieldProps: {
        required: true,
        size: 'small',
        label: 'Launch Price',
        placeholder: '0',
        fullWidth: true
      }
    },
    {
      key: 'isFixedPrice',
      name: 'isFixedPrice',
      function: checkBoxFunction.FIX_RECOMMENDED_PRICES,
      conditionDisable: 'isFixedPrice',
      type: fieldType.CHECK_BOX,
      fieldProps: {
        required: true,
        size: 'small',
        label: 'Fix recommended price'
      }
    },
    {
      key: 'isFixCashPrice',
      name: 'isFixCashPrice',
      function: checkBoxFunction.FIX_RECOMMENDED_PRICES,
      type: fieldType.HIDDEN
    },
    {
      key: 'isFixManufacturePrice',
      name: 'isFixManufacturePrice',
      function: checkBoxFunction.FIX_RECOMMENDED_PRICES,
      type: fieldType.HIDDEN
    },
    {
      key: 'isFixThirdPrice',
      name: 'isFixThirdPrice',
      function: checkBoxFunction.FIX_RECOMMENDED_PRICES,
      type: fieldType.HIDDEN
    },
    {
      key: 'cashPrice',
      name: 'cashPrice',
      type: fieldType.NUMBER,
      conditionDisable: 'isFixCashPrice',
      errorField: 'cashPrice',
      control: 'cashPrice',
      fieldProps: {
        size: 'small',
        label: 'Cash Price',
        placeholder: '0',
        fullWidth: true
      }
    },
    {
      key: 'manufacturePrice',
      name: 'manufacturePrice',
      type: fieldType.NUMBER,
      conditionDisable: 'isFixManufacturePrice',
      control: 'manufacturePrice',
      errorField: 'manufacturePrice',
      fieldProps: {
        size: 'small',
        label: 'Manufacture Price',
        placeholder: '0',
        fullWidth: true
      }
    },
    {
      key: 'thirdPrice',
      name: 'thirdPrice',
      type: fieldType.NUMBER,
      conditionDisable: 'isFixThirdPrice',
      errorField: 'thirdPrice',
      control: 'thirdPrice',
      fieldProps: {
        size: 'small',
        label: 'Third Party Price',
        placeholder: '0',
        fullWidth: true
      }
    }
  ]
};

export default function Car() {
  const { mode } = useParams();
  const [state, setState] = useState({
    page: 0,
    size: 5,
    items: [],
    rowCount: 0,
    filter: '',
    type: mode
  });
  const [excelUploading, setExcelUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formMenu, setFormMenu] = useState({ formType: mode, item: undefined });
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();

  const { page, size, items, rowCount, filter, type } = state;
  const { formType } = formMenu;

  const _getData = async () => {
    const result = await carService.listAsync(type, page, filter, size);
    if (result.data) {
      setState({ ...state, items: result.data.items, rowCount: result.data.totalRow });
    }
  };

  useEffect(() => {
    setState({
      ...state,
      type: mode,
      page: 0,
      filter: ''
    });
    setFormMenu({ ...formMenu, formType: mode });
  }, [mode]);

  useEffect(() => {
    _getData();
  }, [page, size, filter, type]);

  const _handleChangePage = (page) => {
    setState({ ...state, page });
  };

  const _handleChangeRowsPerPage = (rowsPerPage) => {
    setState({ ...state, size: rowsPerPage, page: 0 });
  };

  const _handleAddUser = () => {
    setSearchParams({ [mode]: 'new' });
    // setFormMenu({ ...formMenu, item: null });
  };

  const item = useMemo(() => {
    const id = searchParams.get(mode);
    if (!id || id === 'new') {
      setFormMenu({ item: undefined });
      return;
    }
    const car = items.find((x) => x.id === id);
    Object.keys(car).forEach((key) => {
      if (car[key] === null) {
        car[key] = '';
      }
    });
    car.isFixCashPrice = car.cashPrice !== '';
    car.isFixManufacturePrice = car.manufacturePrice !== '';
    car.isFixThirdPrice = car.thirdPrice !== '';
    return JSON.parse(JSON.stringify(car));
  }, [mode, searchParams]);

  const _handleEdit = (id) => {
    // const clonedItem = JSON.parse(JSON.stringify(car));
    setSearchParams({ [mode]: id });
    // setFormMenu({ ...formMenu, item: clonedItem });
  };

  const _handleCancel = (e, reason) => {
    setSearchParams({});
    if (((reason === 'escapeKeyDown' || reason === 'backdropClick') && !isSubmitting) || !reason) {
      setFormMenu({ ...formMenu, item: undefined });
    }
  };

  const submit = async (data) => {
    setIsSubmitting(true);

    const formData = new FormData();

    if (formType === carMode.CAR_MODEL) {
      formData.append('name', data.name);
      formData.append('brandId', data.brandId);
    }

    if (formType === carMode.CAR_TRIM) {
      formData.append('name', data.name);
      formData.append('modelId', data.modelId);
      formData.append('fuelTypeId', data.fuelTypeId);
      formData.append('carTypeId', data.carTypeId);
      formData.append('isActive', data.isActive);
      formData.append('color', data.color);
      if (typeof data.image === 'object') {
        formData.append('image', data.image);
      }
      formData.append('launchPrice', data.launchPrice);
      if (data.isFixCashPrice) {
        formData.append('cashPrice', data.cashPrice || null);
      }
      if (data.isFixManufacturePrice) {
        formData.append('manufacturePrice', data.manufacturePrice || null);
      }
      if (data.isFixThirdPrice) {
        formData.append('thirdPrice', data.thirdPrice || null);
      }
    }
    const result =
      item === null
        ? await carService.createAsync(formType, formData)
        : await carService.updateAsync(data.id, formType, formData);

    if (!result.error) {
      _getData();
    } else {
      const content =
        result.error.data?.message || 'An error has occurred. Please reload and try again.';

      confirm({
        title: <Typography fontWeight="bold">Error</Typography>,
        content,
        cancellationButtonProps: { style: { display: 'none' } }
      });
    }
    setFormMenu({ ...formMenu, item: undefined });
    setIsSubmitting(false);
  };

  const _handleDelete = async (id) => {
    confirm({
      title:
        type === carMode.CAR_MODEL
          ? 'Delete this model that mean dele all trim below'
          : 'Only delete trim where not exist any bid'
    }).then(async () => {
      const result = await carService.deleteAsync(type, id);

      if (!result.error) {
        _getData();
      } else {
        const content =
          result.error.data?.message || 'An error has occurred. Please reload and try again.';

        confirm({
          title: <Typography fontWeight="bold">Error</Typography>,
          content,
          cancellationButtonProps: { style: { display: 'none' } }
        });
      }
    });
  };

  const _handleChangeFilter = (value) => {
    setState({ ...state, filter: value, page: 0 });
  };

  const refFileInput = useRef();

  const onFileSelect = async (ev) => {
    const file = ev?.target?.files?.[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('excel', file);
    setExcelUploading(true);
    if (mode === 'car-model') {
      await carService.uploadExcelCarModel(formData);
      setExcelUploading(false);
      return;
    }
    await carService.uploadExcelCarTrim(formData);
    setExcelUploading(false);
  };
  const onExcelUpload = () => {
    const fileInput = refFileInput.current;
    if (!fileInput) return;
    fileInput.click();
  };
  const onExcelDownload = async () => {
    const reqFile = await (mode === 'car-model'
      ? carService.downloadExcelCarModel(filter)
      : carService.downloadExcelCarTrim(filter));
    downloadFile(reqFile, `${mode}-${filter}.xlsx`);
  };

  return (
    <Page title={`${carMode[mode].title} | Jessicar`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${carMode[mode].title}`}
          </Typography>
          <ButtonGroup>
            <LoadingButton onClick={onExcelUpload} loading={excelUploading} variant="outlined">
              엑셀 업로드
            </LoadingButton>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={refFileInput}
              onChange={onFileSelect}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <LoadingButton onClick={onExcelDownload} variant="outlined">
              엑셀 다운로드
            </LoadingButton>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={_handleAddUser}
            >
              신규 모댈 등록 {`${carMode[mode].title}`}
            </Button>
          </ButtonGroup>
        </Stack>

        <Table
          searchPlaceholder={`Search ${carMode[mode].title}...`}
          data={items}
          configs={configs[mode]}
          rowCount={rowCount}
          page={page}
          rowsPerPage={size}
          searchKey={filter}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
          onChangeSearchKey={_handleChangeFilter}
          onEditRow={_handleEdit}
          onDeleteRow={_handleDelete}
        />

        {JSON.stringify(item)}
        <RightDrawer
          // open={formType === carMode.CAR_MODEL && item !== undefined}
          open={!!searchParams.get('car-model')}
          onClose={() => setSearchParams({})}
        >
          {/* <CarForm
            initialValues={item}
            mode={mode}
            formSchema={formSchema[carMode.CAR_MODEL]}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onCancel={_handleCancel}
            onSubmit={submit}
          /> */}
          <EditCarModel carData={item} />
        </RightDrawer>

        <RightDrawer
          open={!!searchParams.get('car-trim')}
          // open={formType === carMode.CAR_TRIM && item !== undefined}
          onClose={() => setSearchParams({})}
        >
          {/* <CarForm
            initialValues={item}
            mode="car-trim"
            formSchema={formSchema[carMode.CAR_TRIM]}
            cancelButtonProps={{ disabled: isSubmitting }}
            submitButtonProps={{ loading: isSubmitting }}
            onCancel={_handleCancel}
            onSubmit={submit}
          /> */}
          <EditCarTrim carData={item} />
        </RightDrawer>
      </Container>
    </Page>
  );
}
