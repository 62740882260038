import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ImageControl from 'src/components/form/ImageControl';
import SelectControl from 'src/components/form/SelectControl';
import TextControl from 'src/components/form/TextControl';
import carService from 'src/services/car';
import palette from 'src/theme/palette';
import makeForm from 'src/utils/makeForm';
import * as yup from 'yup';
import _ from 'underscore';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';

const defaultValues = {
  // carId
  id: '',
  name: '',
  brandId: '',
  fuelTypeId: '',
  carTypeId: '',
  image: '',
  // aaa,bbb,ccc...
  searchKeyword: ''
};

const schema = yup
  .object()
  .shape({
    name: yup.string().trim().required('Please enter name of model.'),
    brandId: yup.number().required('Please select name of brand.'),
    searchKeyword: yup.string().trim()
  })
  .required();

export default function EditCarModel({ carData }) {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: { ...defaultValues, ...carData },
    resolver: yupResolver(schema)
  });
  const [options, setOptions] = useState({
    brand: [],
    model: [],
    fuelType: [],
    carType: []
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const getOptions = async () => {
    const brand = (await carService.listOptionsAsync('manufacturer'))?.data;
    const model = (await carService.listOptionsAsync('car-model'))?.data;
    const fuelType = (await carService.listOptionsAsync('fuel-type'))?.data;
    const carType = (await carService.listOptionsAsync('car-type'))?.data;
    setOptions({ brand, model, fuelType, carType });
  };
  useEffect(() => {
    getOptions();
  }, []);
  useEffect(() => {
    if (carData) {
      reset(_.pick(carData, Object.keys(defaultValues)));
    } else {
      reset(defaultValues);
    }
  }, [carData]);
  const id = searchParams.get('car-model');
  const editing = (id ?? '') !== '';

  const onSubmit = async (data) => {
    setLoading(true);
    console.log('onSubmit', data);
    const _data = { ...data };
    if (!_data.galleryId) delete _data.galleryId;
    if (!_data.id) delete _data.id;
    if (!_data.carModelSearchId) delete _data.carModelSearchId;
    const fData = makeForm(_data);
    if (editing) {
      console.log('submit for editing', _data);
      const reqSubmit = await carService.updateAsync(data.id, 'car-model', fData);
      setLoading(false);
      return;
    }
    console.log('submit for creation', _data);
    const reqSubmit = await carService.createAsync('car-model', fData);
    setLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: '100%',
        maxWidth: 500,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="h4"
        sx={{
          padding: '10px 20px',
          borderBottom: `1px solid ${palette.grey[400]}`
        }}
      >
        {editing ? 'Edit Car Model' : 'New Car Model'}
      </Typography>
      <Stack paddingX="24px" gap="16px" paddingY="16px">
        <TextControl
          name="name"
          control={control}
          required
          size="small"
          label="Model Name"
          error={errors.name}
          fullWidth
        />
        <TextControl
          name="searchKeyword"
          control={control}
          size="small"
          label="search keyword"
          error={errors.searchKeyword}
          fullWidth
        />
        <SelectControl
          control={control}
          name="brandId"
          required
          size="small"
          label="Brand Name"
          options={options.brand}
          getValue={(i) => i.id}
          getLabel={(i) => i.name}
          fullWidth
          error={errors.brandId}
        />
        <SelectControl
          control={control}
          name="carTypeId"
          required
          size="small"
          label="Car Type"
          options={options.carType}
          getValue={(i) => i.id}
          getLabel={(i) => i.name}
          fullWidth
        />
        <SelectControl
          control={control}
          name="fuelTypeId"
          required
          size="small"
          label="Fuel Type"
          options={options.fuelType}
          getValue={(i) => i.id}
          getLabel={(i) => i.name}
          fullWidth
        />
        <ImageControl control={control} name="image" />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '20px',
          borderTop: `1px solid ${palette.grey[400]}`
        }}
      >
        <LoadingButton
          variant="outlined"
          color="inherit"
          sx={{ paddingX: '20px' }}
          onClick={() => setSearchParams({})}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          sx={{ paddingX: '20px', marginLeft: '20px' }}
          loading={loading}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
}
